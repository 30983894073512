.address {
  max-height: 6rem;
  text-overflow: ellipsis;

  @supports (-webkit-line-clamp: 4) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }
}

/* When we combine the line clamp with the link-plain styling an ::after tag is added with an empty string in content. This results in three ellipsis dots being added on a forth line in safari, even though the content does not exceed four lines */
.addressLink::after {
  content: none;
}
