@import url('@volvo-cars/css/breakpoints.css');

.searchWrapper {
  --top: 24px;
  z-index: 1;

  @media (--v-from-md) {
    width: var(--search-block-width);
  }

  @media (--v-from-lg) {
    position: absolute;
    top: var(--top);
    max-height: calc(100vh - var(--sitenav-topbar-height) - 2 * var(--top));
    inset-inline-start: var(--v-space-l);
    transform: none;
  }
}

.searchWrapperMap {
  @media (--v-from-md) and (--v-until-lg) {
    position: absolute;
    top: var(--top);
    min-height: 0;
    max-height: calc(
      100vh -
      var(--sitenav-topbar-height) -
      var(--top) -
      var(--top)
    );
    transform: translate(-50%, 0); /* centering horisontally */
    inset-inline-start: 50%; /* centering horisontally */
  }
}

:dir(rtl) .searchWrapperMap {
  @media (--v-from-md) and (--v-until-lg) {
    transform: translate(50%, 0); /* centering horisontally */
  }
}

.listWrapper {
  max-height: inherit;
}
