/* Increase touch area for a11y on checkboxes */
.checkbox label {
  display: flex;
  align-items: center;
  height: 2.5rem;
}
/* Center align checkbox with label */
.checkbox input {
  margin-top: 0;
}
